import React, { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useParams } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AppHeader from "../../layouts/AppHeader"; // Ensure correct import path
import "./DashboardCard.css";

const DashboardCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { title } = useParams<{ title: string }>();
  const tokenUrl = `${process.env.REACT_APP_SYSTEM_DOMAIN}/guest-token/${id}`;
  const embedDomain = `${process.env.REACT_APP_SUPERSET_DOMAIN}`;

  const getToken = async () => {
    try {
      const response = await fetch(tokenUrl);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error("Failed to fetch token:", error);
      return null;
    }
  };

  useEffect(() => {
    const embed = async () => {
      if (id) {
        const token = await getToken();
        if (token) {
          const mountPoint = document.getElementById("dashboard");
          if (mountPoint) {
            await embedDashboard({
              id,
              supersetDomain: embedDomain,
              mountPoint,
              fetchGuestToken: () => token,
              dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: true,
                hideTab: true,
              },
            });
          }
        }
      } else {
        console.error("Dashboard ID is not defined.");
      }
    };

    embed();
  }, [id]);

  // Optional: Define the breadcrumb click handler
  const handleBreadcrumbClick = () => {
    console.log("Breadcrumb clicked");
  };

  return (
    <div>
      <AppHeader />
      <Box sx={{ margin: "auto", width: "100%", maxWidth: "80%", paddingTop: "40px" ,paddingBottom: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} style={{ color: "#A2ADD0", fontSize: "14px" }} to="/dashboard">
            <Typography variant="body2">HOME</Typography>
          </Link>
          <Link
            component={RouterLink}
            style={{ color: "#4191FF", fontSize: "14px" }}
            to="/fleet-analytics"
            onClick={handleBreadcrumbClick}
          >
            <Typography variant="body2">FLEET ANALYTICS</Typography>
          </Link>
          <Typography variant="body2">{title}</Typography>
        </Breadcrumbs>
      </Box>
      <div id="dashboard" />
    </div>
  );
};

export default DashboardCard;
