import React from "react";
import {
  Box,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AppHeader from "../../layouts/AppHeader";

const FleetAnalyticsPage: React.FC = () => {
  const dashboards = [
    {
      id: process.env.REACT_APP_DASHBOARD_CAR_ANALYSIS || "default-id",
      title: "Car Analysis",
      description: "View details on car model, registration, and export data.",
      icon: <TimeToLeaveIcon sx={{ fontSize: { xs: 40, md: 50 }, color: "#1976d2" }} />,
      color: "#1976d2",
    },
    {
      id: process.env.REACT_APP_DASHBOARD_FUEL_MANAGEMENT || "default-id",
      title: "Fuel Management",
      description: "Monitor fuel usage, distance covered, and costs.",
      icon: <LocalGasStationIcon sx={{ fontSize: { xs: 40, md: 50 }, color: "#ff9800" }} />,
      color: "#ff9800",
    },
    {
      id: process.env.REACT_APP_DASHBOARD_DRIVER_BEHAVIOUR || "default-id",
      title: "Driver Behaviour Analysis",
      description: "Analyze driver behavior, including idle time and overspeeding.",
      icon: <PsychologyAltIcon sx={{ fontSize: { xs: 40, md: 50 }, color: "#f44336" }} />,
      color: "#f44336",
    },
    {
      id: process.env.REACT_APP_DASHBOARD_INVENTORY || "default-id",
      title: "Inventory",
      description: "View the no of quantity in the stock and details.",
      icon: <LibraryBooksIcon sx={{ fontSize: { xs: 40, md: 50 }, color: "#4caf50" }} />,
      color: "#4caf50",
    },
  ];

  return (
    <div className="App">
      <AppHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f1f4f8",
          minHeight: "100vh",
          paddingX: { xs: "10px", sm: "20px", md: "30px", lg: "40px" },
        }}
      >
        <Grid>
          <Box
            sx={{
              margin: "auto",
              width: "100%",
              maxWidth: { xs: "90%", sm: "85%", lg: "80%" },
              paddingTop: "40px",
            }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                component={RouterLink}
                style={{ color: "#A2ADD0", fontSize: "14px" }}
                to="/dashboard"
              >
                <Typography variant="body2">HOME</Typography>
              </Link>
              <Link
                component={RouterLink}
                style={{ color: "#4191FF", fontSize: "14px" }}
                to="/fleet-analytics"
              >
                <Typography variant="body2">FLEET ANALYTICS</Typography>
              </Link>
            </Breadcrumbs>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                mt: 2,
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
              }}
            >
              Fleet Report Dashboard
            </Typography>
          </Box>
        </Grid>
        <Box
          sx={{
            padding: { xs: "10px", sm: "20px" },
            backgroundColor: "#f1f4f8",
            width: "100%",
            flexGrow: 1,
          }}
        >
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {dashboards.map((dashboard) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={dashboard.id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "16px",
                    boxShadow: "0 6px 18px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    padding: { xs: "5px", sm: "5px", md: "5px", lg: "5px" },
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
                    },
                    textAlign: "center",
                  }}
                >
                  <CardActionArea>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      {dashboard.icon}
                    </Box>
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "1rem", md: "1.25rem" },
                          fontWeight: "bold",
                          color: "#333",
                          marginBottom: "10px",
                        }}
                      >
                        {dashboard.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: { xs: "0.9rem", md: "1rem" },
                          color: "#666",
                          lineHeight: 1.5,
                        }}
                      >
                        {dashboard.description}
                      </Typography>
                     
                    </CardContent>
                  </CardActionArea>
                  <Box sx={{ textAlign: "center", padding: "10px" }}>
                        <Button
                          component={RouterLink}
                          to={`/fleet-analytics/${dashboard.title}/${dashboard.id}`}
                          variant="contained"
                          sx={{
                            mb: 5,
                            fontSize: { xs: "0.8rem", md: "1rem" },
                            backgroundColor: dashboard.color,
                            "&:hover": {
                              backgroundColor: dashboard.color,
                              opacity: 0.9,
                            },
                          }}
                        >
                          View Dashboard
                        </Button>
                      </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default FleetAnalyticsPage;
